import React from "react";
import { navigateTo } from "gatsby-link";

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

class getBusinessInsuranceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <div className="grid">
        <div className="grid-cell">
          <form
            name="Lewis Mohr LP Business Insurance Form"
            method="post"
            action="/thanks/?form=google-lp"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <div className="grid">
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="file-upload" />
              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </p>
              <div className="grid-cell u-full">
                <label>
                  <input
                    type="text"
                    id="yourNameField"
                    name="yourName"
                    placeholder="Your Name*"
                    aria-label="Your Name"
                    onChange={this.handleChange}
                    required
                  />
                </label>
              </div>
              <div className="grid-cell u-full">
                <label>
                  <input
                    type="text"
                    id="companyNameField"
                    name="companyName"
                    placeholder="Company Name*"
                    aria-label="Company Name"
                    onChange={this.handleChange}
                    required
                  />
                </label>
              </div>
              <div className="grid-cell u-full">
                <label>
                  <input
                    type="email"
                    id="emailField"
                    name="email"
                    placeholder="Email*"
                    aria-label="Email"
                    onChange={this.handleChange}
                    required
                  />
                </label>
              </div>
              <div className="grid-cell u-full">
                <label>
                  <input
                    type="tel"
                    id="phoneField"
                    name="phone"
                    placeholder="Phone Number*"
                    aria-label="Phone Number"
                    onChange={this.handleChange}
                    required
                  />
                </label>
              </div>
              <div className="grid-cell u-full">
                <label>
                  <input
                    type="text"
                    id="zipCodeField"
                    name="zipCode"
                    placeholder="Zip Code*"
                    aria-label="Zip Code"
                    onChange={this.handleChange}
                    maxLength="5"
                    required
                  />
                </label>
              </div>
              <div className="grid-cell u-full">
                <label>
                  <textarea
                    type="message"
                    id="messageField"
                    name="message"
                    placeholder="What type of insurance do you need?*"
                    aria-label="What type of insurance do you need?"
                    onChange={this.handleChange}
                    required
                  />
                </label>
              </div>
              <div className="grid-cell u-full">
                <button type="submit">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default getBusinessInsuranceForm;
