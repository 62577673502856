import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import { navigateTo } from "gatsby-link";

import Layout from "../../components/layout";
import MainBg from "../../components/mainBg";
import GetBusinessInsuranceForm from "../../components/getBusinessInsuranceForm";

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

class GetBusinessInsurance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Get Business Insurance | Lewis Mohr Insurance Agency"
          meta={[
            {
              name: "description",
              content:
                "To inquire about getting insurance for yourself or your business, please fill out the form and we will be in touch within 1 business day. If you need immediate assistance please give us a call."
            }
          ]}
        />
        <MainBg
          mainBg={this.props.data.mainBg}
          imgStyle={{ objectPosition: "top" }}
          alt="Discussing Business Insurance"
        />
        <div className="site-body page-content">
          <div className="grid">
            <div className="grid-cell u-full u-med-1of2">
              <h1 className="landing-title">Business Insurance by Lewis Mohr</h1>
              <p>
                When you get business insurance through Lewis Mohr Insurance Agency, you know you're getting a trusted partner for your business. We've been providing tailored insurance solutions for businesses across Baton Rouge and Louisiana since 1968. 
              </p>
              <p>
                Whether you're looking for a custom business insurance package or just a Small Business Owner's Policy we are here to help. We can even help review your current policies to make sure you have the right coverage and a great rate. Fill out the form to get in touch.  
              </p>
              <div className="grid grid-gutters-lg">
                <div className="grid-cell u-1of2">
                  <Img
                    title="Business Torch for Ethics"
                    alt="BBB Torch for Ethics Logo"
                    fluid={this.props.data.bbbEthics.childImageSharp.fluid}
                    className="ctaBBBAward"
                  />                  
                </div>   
                <div className="grid-cell u-1of2">
                  <Img
                    title="Trusted Choice Insurance"
                    alt="trusted choice logo"
                    fluid={this.props.data.trustedChoice.childImageSharp.fluid}
                    className="ctaTrustedChoice"
                  />
                </div>   
              </div>        
            </div>
            <div className="grid-cell u-full u-med-1of2">
              <div className="ctaForm">
                <h3>Inquire about insurance for your business.</h3>
                <p>We'll be in touch within 1 business day.</p>
                <GetBusinessInsuranceForm />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default GetBusinessInsurance;

export const awards = graphql`
  fragment awards on File {
    childImageSharp {
      fluid(maxWidth: 200) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "jeff-and-brittany-mohr-about-bg.jpg" }) {
      ...mainBg
    }
    trustedChoice: file(
      relativePath: { eq: "footer-award-trusted-choice-insurance-logo.png" }
    ) {
      ...awards
    }
    bbbEthics: file(
      relativePath: { eq: "footer-award-business-ethics-logo.png" }
    ) {
      ...awards
    }
  }
`;
